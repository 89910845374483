import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { createSquad } from '../../providers/squadProvider'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import FlashMessage from '../../components/FlashMessage'

export const NewSquad = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [serverError, setServerError] = useState(null)

  const mutation = useMutation({
    mutationFn: async ({ name, color, about, collaborators }) => {
      return createSquad({ name, color, about, collaborators })
    },
    onSuccess: data => {
      reset()
      navigate('/squads', { state: { message: 'Squad criada com sucesso', type: 'success' } })
    },
    onError: error => {
      setServerError(error.response.data.message)
    },
  })

  const doSubmit = data => {
    mutation.mutate(data)
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center border-b py-4 mb-8">
          <h3 className="text-2xl font-bold">Nova squad</h3>
        </div>

        {serverError && <FlashMessage icon="pi-close" type="error" message={serverError} />}

        <form onSubmit={handleSubmit(doSubmit)}>
          <div className="mb-4 border-b pb-6">
            <div className="md:flex md:items-center md:justify-start">
              <label htmlFor="name" className="block mb-2 mr-4 font-medium md:w-80">
                Nome <br />
                <small>Isso será exibido no perfil da squad</small>
              </label>
              <div className="md:w-2/4">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="w-full rounded-lg border px-4 py-2"
                  {...register('name', { required: 'O nome da squad é obrigatório' })}
                />
                {errors.name && <span className="block text-error text-sm">{errors.name.message}</span>}
              </div>
            </div>
          </div>

          <div className="mb-4 border-b pb-6">
            <div className="md:flex md:items-center md:justify-start">
              <label htmlFor="color" className="block mb-2 mr-4 font-medium md:w-80">
                Escolha uma Cor <br />
                <small>Representará o projeto visualmente</small>
              </label>
              <div>
                <input
                  type="color"
                  name="color"
                  id="color"
                  className="h-8 w-full rounded-lg border"
                  style={{ width: '80px' }}
                  {...register('color', { required: 'A cor da squad é obrigatória' })}
                />
                {errors.color && <span className="block text-error text-sm">{errors.color.message}</span>}
              </div>
            </div>
          </div>

          <div className="mb-4 border-b pb-6">
            <div className="md:flex md:items-center md:justify-start">
              <label htmlFor="name" className="block mb-2 mr-4 font-medium md:w-80">
                Sobre <br />
                <small>Breve descrição sobre o squad</small>
              </label>
              <div className="md:w-2/4">
                <textarea
                  rows={3}
                  name="about"
                  id="about"
                  className="w-full rounded-lg border px-4 py-2"
                  maxLength={40}
                  {...register('about', {
                    required: 'A descrição da squad é obrigatória',
                    maxLength: 'A descrição da squad deve ter no máximo 40 caracteres',
                  })}
                ></textarea>
                <br />
                <small>Até 40 caracteres</small>
                {errors.about && <span className="block text-error text-sm">{errors.about.message}</span>}
              </div>
            </div>
          </div>

          <div className="mb-4 border-b pb-6">
            <div className="md:flex md:items-center md:justify-start">
              <label htmlFor="name" className="block mb-2 mr-4 font-medium md:w-80">
                Quantidade de Colaboradores <br />
                <small>Número de colaboradores</small>
              </label>

              <div className="md:w-2/4">
                <input
                  type="number"
                  min={1}
                  name="collaborators"
                  id="collaborators"
                  className="w-full rounded-lg border px-4 py-2"
                  {...register('collaborators', {
                    required: 'O número de colaboradores é obrigatório',
                    min: 'A squad deve possuir no mínimo 1 colaborador',
                  })}
                />
                {errors.collaborators && <span className="block text-error text-sm">{errors.collaborators.message}</span>}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <Link to="/squads" className="btn btn-outline mr-2">
              Cancelar
            </Link>
            <button type="submit" className="btn btn-primary text-white">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

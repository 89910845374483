import { Header } from './Header'

export const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="lg:container h-screen lg:mx-auto px-[15px]">{children}</div>
    </div>
  )
}

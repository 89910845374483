//React router
import { BrowserRouter } from 'react-router-dom'
import { AppRouter } from './AppRouter'

export const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <AppRouter />
    </BrowserRouter>
  )
}

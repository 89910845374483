import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getSquad, updateSquad } from "../../providers/squadProvider";
import FlashMessage from "../../components/FlashMessage";
import { useState } from "react";

export const EditSquad = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  
  const { register, handleSubmit, reset, formState: { errors }, setError } = useForm();
  const [serverError, setServerError] = useState(null);

  const { data, isLoading, error } = useQuery('getSquad', async () => {
    return await getSquad(id);
  }, {cacheTime: 0});

  const mutation = useMutation({
    mutationFn: async ({ name, hexColor, description, members, id }) => {
        return updateSquad({name, hexColor, description, members, id});
    },
    onSuccess: (data) => {
      console.log(data);
      reset();
      navigate('/squads', { state: { message: 'Squad editada com sucesso', type: 'success' } });
    },
    onError: (error) => {
      console.log(error);
      setServerError(error.response.data.message);
    },
});

  const doSubmit = (data) => {
    data.id = id;
    console.log(data);
    mutation.mutate(data);
  };

  return (
    <>
      <div className="container px-8 py-8">
        <div className="flex justify-between items-center border-b py-4 mb-8">
          <h3 className="text-3xl font-bold">Editar squad</h3>
        </div>

        {isLoading && <div>Carregando...</div>}

        {error && <div>Erro ao carregar os dados</div>}

        {serverError && <FlashMessage icon="pi-close" type="error" message={serverError} />}

        {data && (
          <form onSubmit={handleSubmit(doSubmit)}>
            <div className="mb-4 border-b pb-6">
              <div className="md:flex md:items-center md:justify-start">
                <label htmlFor="name" className="block mb-2 mr-4 font-medium md:w-80">
                  Nome <br />
                  <small>Isso será exibido no perfil da squad</small>
                </label>
                <div className="md:w-2/4">
                  <input 
                    type="text" 
                    name="name" 
                    id="name" 
                    className="w-full rounded-lg border px-4 py-2" 
                    defaultValue={data.name}
                    {...register("name", { 
                      required: "O nome da squad é obrigatório",
                    })}
                  />
                  {errors.name && <span className="block text-error text-sm">{errors.name.message}</span>}
                </div>
              </div>
            </div>
            
            <div className="mb-4 border-b pb-6">
              <div className="md:flex md:items-center md:justify-start">
                <label htmlFor="hexColor" className="block mb-2 mr-4 font-medium md:w-80">
                  Escolha uma Cor <br />
                  <small>Representará o projeto visualmente</small>
                </label>
                <div>
                  <input 
                    type="color" 
                    name="hexColor" 
                    id="hexColor" 
                    defaultValue={data.hexColor}
                    className="h-8 w-full rounded-lg border" 
                    style={{ width: '80px' }}
                    {...register("hexColor", { 
                      required: "A cor da squad é obrigatória"
                    })}
                  />
                  {errors.hexColor && <span className="block text-error text-sm">{errors.hexColor.message}</span>}
                </div>
              </div>
            </div>
            
            <div className="mb-4 border-b pb-6">
              <div className="md:flex md:items-center md:justify-start">
                <label htmlFor="description" className="block mb-2 mr-4 font-medium md:w-80">
                  Sobre <br />
                  <small>Breve descrição sobre o squad</small>
                </label>
                <div className="md:w-2/4">
                  <textarea 
                    rows={3} 
                    name="description" 
                    id="description" 
                    defaultValue={data.description}
                    maxLength={40}
                    className="w-full rounded-lg border px-4 py-2"
                    {...register("description", { 
                      required: "A descrição da squad é obrigatória",
                      maxLength: "A descrição da squad deve ter no máximo 40 caracteres",
                    })}
                  ></textarea>
                  <br /><small>Até 40 caracteres</small>
                  {errors.description && <span className="block text-error text-sm">{errors.description.message}</span>}
                </div>
              </div>
            </div>

            <div className="mb-4 border-b pb-6">
              <div className="md:flex md:items-center md:justify-start">
                <label htmlFor="members" className="block mb-2 mr-4 font-medium md:w-80">
                  Quantidade de Colaboradores <br />
                  <small>Número de colaboradores</small>
                </label>

                <div className="md:w-2/4">
                  <input 
                    type="number" 
                    min={1} 
                    name="members" 
                    id="members" 
                    defaultValue={data.members}
                    className="w-full rounded-lg border px-4 py-2" 
                    {...register("members", { 
                      required: "O número de colaboradores é obrigatório", min: "A squad deve possuir no mínimo 1 colaborador",
                    })}
                  />
                  {errors.members && <span className="block text-error text-sm">{errors.members.message}</span>}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end">
              <Link to="/squads" className="btn btn-outline mr-2">Cancelar</Link>
              <button type="submit" className="btn btn-primary text-white">Salvar</button>
            </div>

          </form>
        )}

      </div>
    </>
  );
}

export const CalculatorIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.318 19.535C3.636 21 5.758 21 10 21C14.243 21 16.364 21 17.682 19.535C19 18.072 19 15.714 19 11C19 6.286 19 3.929 17.682 2.464C16.364 1 14.242 1 10 1C5.757 1 3.636 1 2.318 2.464C1 3.93 1 6.286 1 11C1 15.714 1 18.071 2.318 19.535Z" stroke="#475467" stroke-width="1.5"/>
        <path d="M5 7C5 6.535 5 6.303 5.051 6.112C5.11919 5.85787 5.25303 5.62614 5.43909 5.44009C5.62514 5.25403 5.85687 5.12019 6.111 5.052C6.304 5 6.536 5 7 5H13C13.465 5 13.697 5 13.888 5.051C14.1421 5.11919 14.3739 5.25303 14.5599 5.43909C14.746 5.62514 14.8798 5.85687 14.948 6.111C15 6.304 15 6.536 15 7C15 7.464 15 7.697 14.949 7.888C14.8808 8.14213 14.747 8.37386 14.5609 8.55991C14.3749 8.74597 14.1431 8.87981 13.889 8.948C13.697 9 13.464 9 13 9H7C6.535 9 6.303 9 6.112 8.949C5.85787 8.88081 5.62614 8.74697 5.44009 8.56091C5.25403 8.37486 5.12019 8.14313 5.052 7.889C5 7.696 5 7.464 5 7Z" stroke="#475467" stroke-width="1.5"/>
        <path d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z" fill="#475467"/>
        <path d="M6 17C6.55228 17 7 16.5523 7 16C7 15.4477 6.55228 15 6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17Z" fill="#475467"/>
        <path d="M10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13Z" fill="#475467"/>
        <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" fill="#475467"/>
        <path d="M14 13C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13Z" fill="#475467"/>
        <path d="M14 17C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15C13.4477 15 13 15.4477 13 16C13 16.5523 13.4477 17 14 17Z" fill="#475467"/>
      </svg>

    </>
  )
}

export const HourglassIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6.00003C18 7.59133 17.3679 9.11746 16.2426 10.2427C15.1174 11.3679 13.5913 12 12 12C10.4087 12 8.88258 11.3679 7.75736 10.2427C6.63214 9.11746 6 7.59133 6 6.00003V0.857178H18V6.00003Z" stroke="#475467" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.57153 0.857178H21.4287M2.57153 23.1429H21.4287M18.0001 18C18.0001 16.4087 17.368 14.8826 16.2427 13.7574C15.1175 12.6322 13.5914 12 12.0001 12C10.4088 12 8.88268 12.6322 7.75746 13.7574C6.63225 14.8826 6.0001 16.4087 6.0001 18V23.1429H18.0001V18Z" stroke="#475467" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </>
  )
}
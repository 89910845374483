import Logo from '../../assets/logo.svg'
import { Link } from 'react-router-dom'

export const Header = () => {
  const headerButtons = [
    { label: 'Início', route: '/' },
    /* { label: 'Dashboard', route: '/dashboard' }, */
    { label: 'Projetos', route: '/projetos' },
    { label: 'Squads', route: '/squads' },
    /*     { label: 'Usuários', route: '/usuarios' },*/
  ]

  return (
    <div>
      <header className="bg-white shadow-md mb-5">
        <div className="lg:container lg:mx-auto flex items-center p-4">
          <img src={Logo} alt="Logo" className="h-8 w-auto" />

          <ul className="ml-8 flex space-x-4">
            {headerButtons.map((button, index) => (
              <li key={index} className="text-info-content cursor-pointer">
                <Link to={button.route}>
                  <button>{button.label}</button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </header>
    </div>
  )
}

import { useEffect, useState } from "react";
import { createArrayOfDays, createArrayOfMonths, createArrayOfWeeks } from "../../services/utils";
import config from "../../config";

const ProjectBoard = ({ projects, totalSquads, showBy, children }) => {
  const [titles, setTitles] = useState([]);
  const [projectLines, setProjectLines] = useState([]);

  useEffect(() => {

    generateTitles();

  }, [projects, showBy]);

  function generateTitles() {
    const titles = [];

    if (showBy === 'day') {
      const items = createArrayOfDays(projects);
      items.forEach((item, index) => {
        titles.push(
          <th key={index} className={`text-center`} style={{ maxWidth: `${config[showBy]}px`, minWidth: `${config[showBy]}px` }}>
            <span className="mx-1 rounded bg-gray-100 text-center text-sm font-light flex justify-center items-center">
              {item}
            </span>
          </th>
        );
      });
    }

    if (showBy === 'week') {
      const items = createArrayOfWeeks(projects);
      items.forEach((item, index) => {
        titles.push(
          <th key={index} className={`text-center`} style={{ maxWidth: `${config[showBy]}px`, minWidth: `${config[showBy]}px` }}>
            <span className="mx-1 rounded bg-gray-100 text-center text-sm font-light flex justify-center items-center">
              Semana {item}
            </span>
          </th>
        );
      });
    }
    
    if (showBy === 'month') {
      let items = createArrayOfMonths(projects);

      if (items.length === 0) {
        items = [1,2];
      }

      items.forEach((item, index) => {
        titles.push(
          <th key={index} className={`text-center`} style={{ maxWidth: `${config[showBy]}px`, minWidth: `${config[showBy]}px` }}>
            <span className="mx-1 rounded bg-gray-100 text-center text-sm font-light flex justify-center items-center">
              Mês {item}
            </span>
          </th>
        );
      });
    }
    setTitles(titles);

    generateProjectLines(titles);
  }

  function generateProjectLines(titles) {
    const lines = [];

    if (titles.length === 0) {
      return;
    }

    // create array with total de positions
    const cols = new Array(titles.length).fill("");

    for (let i = 0; i < totalSquads * 3; i++) {
      lines.push(
        <tr key={i}>
          {cols.map((title, index) => (
            <td className={`text-center`} key={index}>
              <span className="mx-1 rounded bg-slate-100 hover:bg-slate-200 text-center text-sm font-light flex justify-center items-center">
                &nbsp;
              </span>
            </td>
          ))}
        </tr>
      );
    }

    setProjectLines(lines);
  }

  return (
    <>
      <div className="w-full max-h-[400px] min-h-[400px] overflow-auto relative">
        <table className={`w-full`}>
          <thead className="sticky top-0 z-50">
            <tr>
              {titles}
            </tr>
          </thead>
          <tbody>
            {projectLines}
          </tbody>
        </table>
        {children}
      </div>
    </>
  );
};

export default ProjectBoard;
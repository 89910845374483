import React from 'react'
import { useQuery } from 'react-query'
import { getProject } from '../../providers/projectProvider'
import { useParams } from 'react-router'
import { CalculatorIcon, HourglassIcon } from '../../components/Icons'
import { Link } from 'react-router-dom'

const calDaysInProject = (hours) => {
  return Math.ceil(hours / 8)
}

const beforeProjects = (projects, projectId) => {
  return projects.map(project => project.name)
}

const ConfirmProject = () => {
  const { id } = useParams()
  const { data, isLoading, error } = useQuery('projectInfo', async () => {
    const project = await getProject(id)

    const squads = project.squads.map(squad => {
      squad.daysInProject = calDaysInProject(squad.hoursOnProject)
      squad.projectsToFinishFirst = beforeProjects(squad.progressInfo.projectsToFinishFirst)

      return squad
    })
    project.squads = squads
    return project
  }) 
  
  return (
    <div className="flex space-y-5 flex-col mt-5">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col md:w-5/12 lg:w-3/12 mr-20">
          <span className="text-md">Disponibilidade de Squads</span>
          <span className="text-sm text-gray-500">Resultado com base nos dados informados</span>
        </div>

        {isLoading && <div>Carregando...</div>}

        {error && <div>Erro ao carregar os dados</div>}

        {data && (
          <div className="flex flex-col sm:mt-3 md:w-11/12 space-y-6">
            {data.squads && data.squads.map(squad => (
              <div key={squad.squadId} className="p-5 flex flex-col text-gray-500 border rounded-lg bg-white">
                <span className="text-2xl font-bold text-slate-800">
                  <div className="box-color" style={{ backgroundColor: squad.hexColor }}></div>
                  {squad.squadName ?? 'Squad de teste'}
                </span>
                <div className="flex">
                  <span><i className="pi pi-clock"></i> {squad.hoursOnProject}h</span>
                  <span className="ml-3"><i className="pi pi-users"></i> {squad.members}</span>
                </div>
                <hr className="w-full self-center my-3" />
                <span><i className="pi pi-clock"></i> Início em {squad.progressInfo.daysUntilStartOnProject} dias, a squad está ocupada com {squad.projectsToFinishFirst.length > 1 ? 'os projetos' : 'o projeto'} <strong>{squad.projectsToFinishFirst.join(', ')}</strong></span>
                <span><i className="pi pi-calendar"></i> Entrega em {squad.daysInProject} {squad.daysInProject > 1 ? 'dias' : 'dia'}</span>
                <span>
                  <div className="flex justify-start items-center space-x-1">
                    <HourglassIcon />
                    <span>{squad.hoursProducedByDay} horas por dia, {squad.members} pessoas disponíveis</span>
                  </div>
                </span>
                {/* <span className="font-semibold">
                  <div className="flex justify-start items-center space-x-1">
                    <CalculatorIcon /> 
                    <span>
                      Custo: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(squad.cost ?? 0) }
                    </span>
                  </div>
                </span> */}
              </div>
            ))}
          </div>
        )}

      </div>
      <div className="flex justify-end pt-3">
        <Link to={`/projetos`} className="btn btn-outline mr-3">Voltar</Link>
        <Link to={`/projetos/novo-projeto`} className="btn btn-primary text-white">Cadastrar novo projeto</Link>
      </div>
    </div>
  )
}

export default ConfirmProject

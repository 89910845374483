import dayjs from "dayjs";

export function converterFormatoData(data) {
    var partesData = data.split('/');
    var dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
    return dataFormatada;
}

export function calcularDiferencaEmDias(dataInicio, dataFim) {
    var inicio = converterFormatoData(dataInicio);
    inicio = new Date(inicio);

    var fim = converterFormatoData(dataFim);
    fim = new Date(dataFim);

    var diferencaEmMilissegundos = fim - inicio;

    var diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

    return Math.round(diferencaEmDias);
}

export function differenceBetweenDates(start, end, unit) {
    if (start.includes('/')) {
        const [day, month, year] = start.split('/')
        start = `${year}-${month}-${day}`
    }

    if (end.includes('/')) {
        const [day, month, year] = end.split('/')
        end = `${year}-${month}-${day}`
    }

    const startDate = dayjs(start)
    const endDate = dayjs(end)

    return endDate.diff(startDate, unit);
}

export function getGreaterInterval(projects) {
    if (projects.length > 0) {
        const { startDate, endDate } = getStartAndEndDates(projects);
        return differenceBetweenDates(startDate, endDate, 'day');
    }
    return 30;
}

export function getStartAndEndDates(projects) {
    if (projects.length > 0) {

        const minDate = projects.reduce((min, p) => {
            if (p.startDate === '') return min;
            return dayjs(formatDate(p.startDate)) < dayjs(formatDate(min.startDate)) ? p : min;
        });

        const maxDate = projects.reduce((max, p) => {
            if (p.endDate === '') return max;
            return dayjs(formatDate(p.endDate)) > dayjs(formatDate(max.endDate)) ? p : max;
        });

        return {
            startDate: minDate.startDate,
            endDate: maxDate.endDate,
        }
    }
}

export function getTotalSquads(projects) {
    let totalSquads = 0
    projects.forEach(project => {
        totalSquads += project.squads.length
    })
    return totalSquads
}

// return an array of days between start and end dates format DD/MM/YYYY
export function createArrayBy(unit, projects) {
    const { startDate, endDate } = getStartAndEndDates(projects);
    const items = [];
    const total = differenceBetweenDates(startDate, endDate, unit);
    
    for (let i = 0; i <= total; i++) {
        if (unit === 'day') {
            items.push(dayjs(formatDate(startDate)).add(i, 'd').locale('pt-br').format('DD/MM/YYYY'));
        } else items.push(i);
    }
    return items;
}


export function createArrayOfDays(projects) {
    const { startDate, endDate } = getStartAndEndDates(projects);
    const days = [];
    const totalDays = differenceBetweenDates(startDate, endDate, 'day');
    
    for (let i = 0; i <= (totalDays * 2); i++) {
        days.push(dayjs(formatDate(startDate)).add(i, 'd').locale('pt-br').format('DD/MM/YYYY'));
    }
    return days;
}

export function createArrayOfWeeks(projects) {
    const { startDate, endDate } = getStartAndEndDates(projects);
    const weeks = [];
    const totalWeeks = differenceBetweenDates(startDate, endDate, 'day');
    
    for (let i = 1; i <= totalWeeks; i++) {
        weeks.push(i);
    }
    return weeks;
}

export function createArrayOfMonths(projects) {
    const { startDate, endDate } = getStartAndEndDates(projects);
    const months = [];
    const totalMonths = differenceBetweenDates(startDate, endDate, 'month');
    
    for (let i = 1; i <= totalMonths; i++) {
        months.push(i);
    }
    return months;
}

export function formatDate(date) {
    return date.includes('/') ? date.split('/').reverse().join('-') : date;
}

export function getPercentageOfTime(time, days) {

    const daysInTime = {
        day: 1,
        week: 7,
        month: 30,
    };

    const widthInTime = {
        day: 100,
        week: 300,
        month: 800,
    };

    const percentOfTime = (days * 100) / daysInTime[time];
    return Math.ceil((widthInTime[time] * percentOfTime) / 100);
}
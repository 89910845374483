import { useEffect, useState } from 'react'
import SquadItem from '../SquadItem'
import { differenceBetweenDates, getPercentageOfTime } from '../../services/utils';
import config from '../../config';

export default function ProjectItem({ project, unit, top, left, width, height, openModal }) {
  const [styles, setStyles] = useState({})
  const [squadList, setSquadList] = useState([])

  useEffect(() => {
    setStyles({
      display: 'block',
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      border: '1px dotted #ccc',
    })

    generateSquadList();
  }, [project, width]);

  const truncateText = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }

    return text;
}

const sizeOfSquad = (project, squad) => {

  const totalOfSquad = differenceBetweenDates(squad.startDate, squad.endDate, unit);
  const toStart = differenceBetweenDates(project.startDate, squad.startDate, unit);

  // calcular quantos % tem cada dia no projeto
  let oneFraction = config[unit] * 0.99;

  let widthSquad = oneFraction;
  let start = 0;

  if (unit === 'day') {
    widthSquad = (totalOfSquad + 1) * oneFraction;
    start = toStart * oneFraction;
  }

  if (unit === 'week') {
    oneFraction = (width / (project.totalDays + 1)) * 7;
    widthSquad = (totalOfSquad + 1) * oneFraction;
    start = toStart * oneFraction;
  }

  if (unit === 'month') {

    const daysOfSquad = differenceBetweenDates(squad.startDate, squad.endDate, 'day');
    const percentOfSquadIntoMonth = (daysOfSquad * 100) / project.totalDays;

    widthSquad = (width / 100) * percentOfSquadIntoMonth;
    
    const daysToStart = differenceBetweenDates(project.startDate, squad.startDate, 'day');
    const percentOfStartIntoMonth = (daysToStart * 100) / project.totalDays;

    start = (width / 100) * percentOfStartIntoMonth;
  }

  return { width: widthSquad, start };
};


  const generateSquadList = () => {
    const squadItems = [];

    if (project.squads) {
      project.squads.map((squad, index) => {

        const size = sizeOfSquad(project, squad);

        const item = (
          <SquadItem key={index} squad={squad} top={index * 30} width={size.width} start={size.start}>
            <div className="flex justify-start items-center">
                <div className="mr-2 rounded-full w-4 h-4" style={{ backgroundColor: squad.hexColor }}></div>
                <span className="block text-sm truncate overflow-hidden">
                    {(size.width <= 100) && truncateText(squad.squadName, 1)}
                    {(size.width > 100 && size.width < 200) && truncateText(squad.squadName, 2)}
                    {(size.width >=200 && size.width < 300) && truncateText(squad.squadName, 5)}
                    {(size.width > 300) && squad.squadName}
                </span>
            </div>

            {size.width > 100 && (
                <div className="flex justify-end items-center">
                    <span className="block text-xs mr-3">
                        <i className="pi pi-clock"></i> {squad.hours}h
                    </span>
                    <span className="block text-xs">
                        <i className="pi pi-users"></i> {squad.members}
                    </span>
                </div>
            )}
          </SquadItem>
        );

        squadItems.push(item);
      });
    }

    setSquadList(squadItems);
  }


  return (
    <>
      <div
        className="px-2 rounded-lg"
        style={styles}
        onClick={() => openModal(project)}
      >
        <span className="block text-xs font-semibold mb-[0.1rem]">
          {project.projectName}
          {/* <small> (largura {width}px por {project.totalDays} dias)</small> */}
        </span>
        <div className="relative">{squadList}</div>
      </div>
    </>
  )
}

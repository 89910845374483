import { useEffect, useState } from "react";

export default function SquadItem({ squad, top, width, start, children }) {

    const [styles, setStyles] = useState({});

    useEffect(() => {
        setStyles({
            width: `${width}px`,
            top: `${top}px`,
            left: `${start}px`,
            height: '20px',
            // border: '1px solid red',
        });
    }, [squad, width, start]);

    return (
        <>
            <div
                className="absolute rounded-full bg-white shadow hover:bg-slate-100 text-sm"
                style={styles}
            >
                <div className="flex justify-between items-center px-2">
                    {children}
                </div>
            </div>
        </>
    );
}
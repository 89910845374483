export const ModalProject = ({ project, onClose }) => {

  return (
    <dialog id="modalProject" className="modal">
      <div className="modal-box flex flex-col">
        <h3 className="my-5 font-bold text-lg border-b">{project?.projectName}</h3>

        <div>
          <div className="flex justify-between items-center">
            <div><strong>Início: </strong>{project?.startDate}</div>
            <div><strong>Término: </strong>{project?.endDate || 'Não informado'}</div>
          </div>

          {/* List squads */}
          <div className="mt-4">
            <h4 className="font-bold text-lg mb-4">Squads</h4>
            <div className="flex flex-col flex-wrap">
              {project?.squads && project?.squads.map((squad, index) => (
                <>
                  <div className={`py-2 px-4 mb-3 rounded-lg hover:bg-slate-200 ${index % 2 !== 0 ? 'bg-slate-100' : ''}`}>
                    <div className={`flex justify-between items-center`} key={index}>
                      <div>
                        <div className="flex justify-start items-center">
                          <div className="w-6 h-6 rounded-full mr-2" style={{ background: squad.hexColor }}></div>
                          <span>{squad.squadName}</span>
                        </div>

                        <div className="flex justify-start items-center text-xs mt-2 space-x-2">
                          <div><small><strong>Período: </strong></small></div>
                          <div><small>{squad.startDate}</small></div>
                          <div><small> - </small></div>
                          <div><small>{squad.endDate}</small></div>
                        </div>
                      </div>

                      <div class="flex justify-end items-center">
                        <span className="block text-xs mr-3">
                            <i className="pi pi-clock"></i> {squad.hours}h
                        </span>
                        <span className="block text-xs">
                            <i className="pi pi-users"></i> {squad.members}
                        </span>
                      </div>
                    </div>

                    
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        
        <div className="modal-action border-t pt-4">
          <form method="dialog">
            <button className="btn btn-outline btn-sm">Fechar</button>
          </form>
        </div>
      </div>
    </dialog>
  )
}

import { useQuery } from 'react-query'
import { deleteSquad, getSquads } from '../../providers/squadProvider'
import { Link, useLocation } from 'react-router-dom'
import FlashMessage from '../../components/FlashMessage'
import { useEffect, useState } from 'react'

export const Squads = () => {
  const location = useLocation()
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message)
      location.state.message = null
    }
  }, [])

  const { data, isLoading, error, refetch } = useQuery('squads', async () => {
    return await getSquads()
  })

  const destroySquad = async (id) => {
    if (window.confirm('Deseja realmente excluir esta Squad?')) {
      const res = await deleteSquad(id);
      
      if (!res) {
        alert('Erro ao excluir Squad');
        return;
      }
      refetch();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center border-b py-4 mb-8">
        <h3 className="text-2xl font-bold">Lista de Squads</h3>

        <Link to="/squads/nova-squad" className="btn">
          <i className="pi pi-plus-circle"></i> Nova Squad
        </Link>
      </div>

      {message && <FlashMessage icon="pi-check" type="success" message={message} />}

      <div className="overflow-x-auto">
        <table className="table table-zebra">
          <thead>
            <tr>
              <th className="w-4"></th>
              <th>Nome</th>
              <th className="w-12">Colaboradores</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan="3">Carregando...</td>
              </tr>
            )}

            {error && (
              <tr>
                <td colSpan="3">Erro ao carregar os dados</td>
              </tr>
            )}

            {data &&
              data.map((squad, index) => (
                <tr key={index} className="hover">
                  <td>
                    <div className="flex items-center">
                      <div
                        className="w-8 h-8 rounded-lg border border-gray-400 mr-4 text-center text-white p-1"
                        style={{ backgroundColor: squad.hexColor }}
                      >
                        <span>{squad.name.substring(0, 2).toUpperCase()}</span>
                      </div>
                    </div>
                  </td>
                  <td>{squad.name}</td>
                  <td>{squad.members}</td>
                  <td className="text-right">
                    <div className="flex justify-end items-center">
                      <Link to={`/squads/editar-squad/${squad.id}`} className="btn btn-primary btn-sm">
                        <i className="pi pi-pencil text-white"></i>
                      </Link>
                      <button type="button" onClick={() => destroySquad(squad.id)} className="btn btn-danger btn-sm ml-2">
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

import dayjs from 'dayjs'
import http from '../services/http'
import { differenceBetweenDates, getGreaterInterval, getTotalSquads } from '../services/utils'

export async function getProjects(page) {
  page = page || 1
  const response = await http.get('/projects?page='+ page)
  return response.data
}

export async function getProject(id) {
  const response = await http.get(`/projects/${id}`)
  return response.data
}

export async function reportProject(days) {
  // se não passar o parâmetro days, o valor padrão é 60
  days = days || 60

  const response = await http.get(`/projects/report/generate?days=${days}`)

  let projects = response.data.productivityReport

  const totalSquadsGeneral = getTotalSquads(projects);

  const now = dayjs().locale('pt-BR').format('DD/MM/YYYY');

  projects = projects.map(project => {
    const {startDate, endDate} = project

    let start = startDate || dayjs().locale('pt-BR').format('DD/MM/YYYY');
    let end = endDate || startDate;

    const totalDays = differenceBetweenDates(start, end, 'day')
    const totalWeeks = differenceBetweenDates(start, end, 'week')
    const totalMonths = differenceBetweenDates(start, end, 'month')
    const totalSquads = project.squads.length
    const daysToStart = differenceBetweenDates(now, startDate, 'day')
    const weeksToStart = differenceBetweenDates(now, startDate, 'week')
    const monthsToStart = differenceBetweenDates(now, startDate, 'month')
    
    return {
      ...project,
      totalDays,
      totalWeeks,
      totalMonths,
      daysToStart,
      weeksToStart,
      monthsToStart,
      totalSquads,
    }
  });

  const greaterInterval = getGreaterInterval(projects)

  const productivityReport = {
    greaterInterval,
    totalSquads: totalSquadsGeneral,
    projects
  }

  return productivityReport
}

export async function createProject(project) {

  const { name, description, revenue } = project;
  const payload = {
    name,
    description,
    revenue: parseFloat(revenue.replace('.', '').replace(',', '.')),
    squads: []
  }

  payload.squads = project.squads.map(squad => {
    return {
      squadId: squad.squadId,
      hours: parseInt(squad.hours),
    };
  });

  try {
    const response = await http.post('/projects', payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function updateProject(id, project) {

  const { name, description, revenue } = project;
  const payload = {
    name,
    description,
    revenue: parseFloat(revenue.toString().replace('.', '').replace(',', '.')),
    squads: []
  }

  payload.squads = project.squads.map(squad => {
    return {
      squadId: squad.squadId,
      hours: parseInt(squad.hours),
    };
  });

  try {
    const response = await http.put(`/projects/${id}`, payload)
    return response.data
  } catch (e) {
    throw new Error(e)
  }
}

export async function downloadTemplate() {

}

export async function importProjects(fileInput) {
  const formData = new FormData()
  formData.append('file', fileInput)

  try {
    const response = await http.post('/projects/batch', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response.status === 201) {
      return true
    }

    return false
  } catch (e) {
    throw new Error(e)
  }
}
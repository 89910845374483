import React, { useEffect, useState } from 'react'
import { set, useFieldArray, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ModalOptions } from '../../../../components/ModalOptions'
import { useMutation } from 'react-query'
import { createProject } from '../../../../providers/projectProvider'

const StepData = ({ nextStep, squads, loadingSquads }) => {
  const [squadsProject, setSquadsProject] = useState([])
  const [data, setData] = useState({})

  const {register, control, handleSubmit, reset, formState: { errors }} = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "squads",
  })

  const { mutate, isLoading, error } = useMutation({
    mutationFn: async (data) => {
      return await createProject(data)
    },
    onSuccess: data => {
      reset()
      nextStep(data)
    },
    onError: error => {
      console.log("ERROR", error)
    }
  })

  const doSubmit = dataForm => {
    console.log("DADOS", dataForm)

    mutate(dataForm)
  }

  const closeModal = () => {
    document.getElementById('modalOptions').close()
  }

  const addSquad = squad => {
    const squadSelect = squads.find(item => item.id === squad)

    append({ name: squadSelect.name, hours: 0, squadId: squadSelect.id })
  }

  const removeSquad = i => {
    remove(i)
  }

  const openModal = async () => {
    document.getElementById('modalOptions').showModal()
  }

  return (
    <div>
      <form onSubmit={handleSubmit(doSubmit)}>
        <div className="flex space-y-5 flex-col mt-5 divide-y">
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex flex-col md:w-5/12 lg:w-3/12">
              <span className="text-md">Nome do Projeto</span>
              <span className="text-sm text-gray-500">Isso será exibido no perfil do Projeto</span>
            </div>

            <div className="flex flex-col sm:mt-3 md:w-6/12 ">
              <input
                type="text"
                id="name"
                placeholder="Nome do Projeto"
                value={data.name}
                className="input input-md input-bordered w-full m a x-w-xs"
                {...register('name', { required: 'O nome do projeto é obrigatório' })}
              />
              {errors.name && <span className="block text-error text-sm">{errors.name.message}</span>}
              {/* <div className="join mt-3">
                <input className="w-[180px] input input-bordered join-item" readOnly placeholder="fiserv.com/project/" />
                <input type="text" placeholder="Nome do Projeto" className="input input-md join-item input-bordered w-full m a  x-w-xs" />
              </div> */}
            </div>
          </div>

          <div className="flex flex-col md:flex-row pt-3 items-center">
            <div className="flex flex-col md:w-5/12 lg:w-3/12">
              <span className="text-md">Sobre</span>
              <span className="text-sm text-gray-500">Breve descrição sobre o projeto</span>
            </div>
            <div className="flex flex-col sm:mt-3 md:w-6/12 ">
              <textarea
                className="textarea textarea-bordered"
                id="about"
                placeholder="Sobre o projeto"
                value={data.about}
                {...register('description', {
                  required: 'A descrição do projeto é obrigatória',
                  maxLength: 'A descrição do projeto deve ter no máximo 40 caracteres',
                })}
              ></textarea>
              {errors.about && <span className="block text-error text-sm">{errors.about.message}</span>}
            </div>
          </div>

          <div className="flex flex-col md:flex-row pt-3 items-center">
            <div className="flex flex-col md:w-5/12 lg:w-3/12 ">
              <span className="text-md">Alocação de Squads</span>
              <span className="text-sm text-gray-500">Squads e horas esperadas</span>
            </div>
            <div className="flex flex-col space-y-3 sm:mt-3 md:w-6/12">
              {fields.map((item, index) => (
                <div key={index} className="flex space-x-2">
                  <div className="join">
                    <input 
                      type="hidden"
                      defaultValue={item.id}
                      {...register(`squads.${index}.squadId`, { required: 'O id da squad é obrigatório' })}
                    />
                    <input className="input input-bordered join-item w-[100px]" readOnly placeholder="Saquad" />
                    <input
                      type="text"
                      placeholder="squad"
                      defaultValue={item.name}
                      {...register(`squads.${index}.name`, { required: 'O nome da squad é obrigatório' })}
                      className="input input-md join-item input-bordered w-full m a  x-w-xs"
                    />
                  </div>
                  <div className="join">
                    <input className="input input-bordered join-item w-[100px]" readOnly placeholder="Horas" />
                    <input
                      type="number"
                      placeholder="00"
                      min={0}
                      defaultValue={item.hours}
                      {...register(`squads.${index}.hours`, { required: 'A quantidade de horas é obrigatória' })}
                      className="input input-md join-item input-bordered w-full m a x-w-xs"
                    />
                  </div>

                  {fields.length === 1 && (
                    <span
                      className="btn w-[50px] btn-primary text-white"
                      onClick={() => {
                        removeSquad(0)
                      }}
                    >
                      -
                    </span>
                  )}
                  {index + 1 === fields.length ? (
                    <span className="btn w-[50px] btn-primary text-white" onClick={openModal}>
                      +
                    </span>
                  ) : (
                    <span
                      className="btn w-[50px] btn-primary text-white"
                      onClick={() => {
                        removeSquad(index)
                      }}
                    >
                      -
                    </span>
                  )}
                </div>
              ))}

              {fields.length === 0 && (
                <span className="btn w-[50px] btn-primary text-white" onClick={openModal}>
                  +
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row pt-3">
            <div className="flex flex-col md:w-5/12 lg:w-3/12 ">
              <span className="text-md">Receita estimada</span>
              <span className="text-sm text-gray-500">Squads e horas esperadas</span>
            </div>
            <div className="flex sm:mt-3 md:w-6/12">
              <div className="flex-col flex w-full">
                <div className="join">
                  <input className="w-[120px] input input-bordered join-item" readOnly placeholder="R$" />
                  <input
                    type="number"
                    min={0}
                    placeholder="0,0"
                    id="revenue"
                    value={data.revenue}
                    className="input input-md join-item input-bordered w-full m a  x-w-xs"
                    {...register('revenue', { required: 'A receita do projeto é obrigatória' })}
                  />
                </div>

                {errors.revenue && <span className="block text-error text-sm">{errors.revenue.message}</span>}
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-3">
            <Link to="/projetos">
              <button className="btn btn-outline mr-3">Cancelar</button>
            </Link>
            <button className={`btn btn-primary text-white ${isLoading ? 'disabled' : ''}`} type="submit">
              {isLoading ? 'Persistindo dados...' : 'Próximo'}
            </button>
          </div>
        </div>
      </form>
      {!loadingSquads && <ModalOptions title="Escolha uma squad" options={squads} onClose={closeModal} onConfirm={addSquad} />}
    </div>
  )
}

export default StepData

import { useEffect, useState } from "react";

export default function FlashMessage({ type, message, icon }) {

    const [visible, setVisible] = useState(false);
    
    useEffect(() => {
        if (!message) {
            setVisible(false);
            return;
        }
        setVisible(true);

        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [message]);

    return (
        <>
            {visible && (
                <div className={`shadow rounded alert alert-${type} mb-4`}>
                    {icon && <i className="pi pi-check"></i>}
                    <span>{message}</span>
                </div>
            )}
        </>
    );
}
import http from "../services/http";

export async function getSquads() {
    const response = await http.get("/squads");
    return response.data;
}

export async function getSquad(id) {
    const response = await http.get(`/squads/${id}`);
    return response.data;
}

export async function createSquad(squad) {

    const payloadData = {
        ...squad, 
        description: squad.about,
        members: parseInt(squad.collaborators),
        hexColor: squad.color,
    };

    console.log("Squad to be created: ", payloadData);

    const response = await http.post("/squads", payloadData);
    return response.data;
}

export async function updateSquad(squad) {
    squad.members = parseInt(squad.members);
    const response = await http.put(`/squads/${squad.id}`, squad);
    return response.data;
}

export async function deleteSquad(id) {
    const response = await http.delete(`/squads/${id}`);
    return response.status === 204 ? true : false;
}
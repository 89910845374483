// React Router
import { Routes, Route } from 'react-router'

// components
import { Layout } from '../components/Layout'

// Pages
import { Home } from '../pages/Home'
import { Projects } from '../pages/Projects'
import { NewProject } from '../pages/NewProject'
import { Squads } from '../pages/Squads'
import { NewSquad } from '../pages/NewSquad'
import { Dashboard } from '../pages/Dashboard'
import { Users } from '../pages/Users'
import { EditSquad } from '../pages/EditSquad'
import ConfirmProject from '../pages/ConfirmProject'
import Page404 from '../pages/Page404'
import EditProject from '../pages/EditProject'

export const AppRouter = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/projetos/novo-projeto" element={<NewProject />} />
        <Route path="/projetos/editar-projeto/:id" element={<EditProject />} />
        <Route path="/projetos/confirmar/:id" element={<ConfirmProject />} />
        <Route path="/squads" element={<Squads />} />
        <Route path="/squads/nova-squad" element={<NewSquad />} />
        <Route path="/squads/editar-squad/:id" element={<EditSquad />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/usuarios" element={<Users />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Layout>
  )
}

import { useEffect, useState } from "react";
import ProjectItem from "../ProjectItem";
import config from "../../config";
import { getPercentageOfTime } from "../../services/utils";
import { ModalProject } from "../ModalProject";

export default function Projects({ projects, unit }) {

    const [listProjects, setListProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState(null);

    useEffect(() => {

        // clear board before generate new
        setListProjects([]);

        // generate new board
        generateListProjects(projects);
    }, [projects, unit]);

    const closeModal = () => {
        setCurrentProject(null);
    }

    const openModal = (project) => {
        setCurrentProject(project);
        document.getElementById('modalProject').showModal();
    }

    const generateListProjects = (projects) => {

        const list = [];

        const startHeight = 30;
        const widthUnit = config[unit];
        const heightSquad = 50;

        let lastHeight = startHeight;
        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            

            let width = 100;
            let startLeft = 0;
            if (unit === 'day') {
                width = (project.totalDays + 1) * widthUnit;
                startLeft = project.daysToStart * widthUnit;
            }

            if (unit === 'week') {
                width = (project.totalWeeks === 0 ? 1 : project.totalWeeks) * widthUnit;
                width = getPercentageOfTime(unit, project.totalDays);
                startLeft = project.weeksToStart * widthUnit;
            }
            
            if (unit === 'month') {
                width = (project.totalWeeks === 0 ? 1 : project.totalWeeks) * widthUnit;

                width = getPercentageOfTime(unit, project.totalDays);

                startLeft = project.monthsToStart * widthUnit;
            }
            width = width < 100 ? 100 : width;

            const item = (
                <ProjectItem 
                    key={i} 
                    project={project} 
                    unit={unit}
                    top={lastHeight} 
                    left={startLeft} 
                    width={width}
                    height={project.totalSquads * heightSquad}
                    openModal={openModal}
                />
            );
            list.push(item);

            lastHeight += (project.totalSquads * heightSquad) + 5;
            startLeft = 0;
        }
        setListProjects(list);
    };

    return (
        <>
            {listProjects}
            <ModalProject project={currentProject} onClose={closeModal} />
        </>
    );
}
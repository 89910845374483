import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getProjects, importProjects } from '../../providers/projectProvider'
import ReactPaginate from 'react-paginate'
import { useEffect, useState } from 'react'
import ModalImportarProjects from '../../components/ModalImportarProjects'

export const Projects = () => {
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1)

  const { data: responseData, isLoading, error, isFetching, isPreviousData } = useQuery({
    queryKey: ['projects', page],
    queryFn: () => getProjects(page),
    keepPreviousData: true,
    staleTime: 5000,
  })

  useEffect(() => {
    if (!isPreviousData && responseData?.hasMore) {
      queryClient.prefetchQuery({
        queryKey: ['projects', page + 1],
        queryFn: () => getProjects(page + 1),
      })
    }
  }, [responseData, isPreviousData, page, queryClient])

  const handlePageClick = (event => {
    const selectedPage = event.selected + 1;
    setPage(selectedPage);
  });

  const openModal = async () => {
    document.getElementById('modalImportProjects').showModal()
  }

  const closeModal = async () => {
    document.getElementById('modalImportProjects').close()
  }

  const handleImport = async () => {
    console.log('Importar')

    // get file from input file element (modal) and send to backend
    const file = document.querySelector('input[type=file]').files[0]
    console.log(file)

    const res = await importProjects(file)
    if (res) {
      queryClient.prefetchQuery({
        queryKey: ['projects', 1],
        queryFn: () => getProjects(1),
      })
    }

    closeModal()
  }

  return (
    <div>
      <div className="flex justify-between items-center border-b py-4 mb-8">
        <h3 className="text-2xl font-bold">Lista de Projetos</h3>

        <div class="flex justify-end items-center space-x-2">
          <Link to="#" className="btn btn-primary" onClick={openModal}>
            <i className="pi pi-file-excel"></i> Importar projetos
          </Link>
          <Link to="/projetos/novo-projeto" className="btn">
            <i className="pi pi-plus-circle"></i> Novo Projeto
          </Link>
        </div>
      </div>

      {/* Modal de importacao de projetos */}
      <dialog id="modalImportProjects" className="modal">
        <div className="modal-box flex flex-col">
          <h3 className="my-5 font-bold text-lg">Importar projetos via excel</h3>

          <p>
            <strong>Atenção:</strong> O arquivo deve estar no formato .xlsx
          </p>

          <p className='mb-4'>
            {/* download do modelo */}
            <a href={`http://localhost:4000/templates/download/create-project`} className="text-blue-500" target="_blank" rel="noreferrer">
              Clique aqui para baixar o modelo
            </a>
          </p>

          <input type="file" name="file_excel" className="file-input file-input-bordered w-full" />
          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-outline">Fechar</button>
            </form>
            
            <button onClick={handleImport} className="btn btn-primary text-white">
              Importar
            </button>
          </div>
        </div>
      </dialog>
      {/* Modal de importacao de projetos */}

      <div className="overflow-x-auto">
        <table className="table table-zebra">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Squads</th>
              <th className="w-12">Receita</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan="4">
                  <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    Carregando dados...
                  </div>
                </td>
              </tr>
            )}

            {error && (
              <tr>
                <td colSpan="4">Erro ao carregar os dados</td>
              </tr>
            )}

            {!isLoading && responseData.data && responseData.data.length > 0 &&
              responseData.data.map((project, index) => (
                <tr key={index} className="hover">
                  <td>{project.name}</td>
                  <td>
                    {project.squads && project.squads.length > 0 && project.squads.map((squad, index) => (
                      <span key={index} className="rounded-lg text-xs border border-gray-300 font-light px-2 py-1 mr-1" style={{ backgroundColor: squad.hexColor }}>{squad.name}</span>
                    ))}
                  </td>
                  <td>
                    {project.revenue.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td className="text-right">
                    <div className="flex justify-end items-center">
                      <Link to={`/projetos/editar-projeto/${project.id}`} className="btn btn-primary btn-sm text-white">
                        <i className="pi pi-pencil"></i>
                      </Link>
                      <Link to="#" className="btn btn-danger btn-sm ml-2">
                        <i className="pi pi-trash"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {!isLoading && responseData.data && responseData.data.length > 0 && (
        <div className='mb-4 flex justify-center items-center'>
          <ReactPaginate
            breakLabel="..."
            nextLabel="próxima >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={responseData.meta.totalPages}
            previousLabel="< anterior"
            renderOnZeroPageCount={null}
            containerClassName='join space-x-1 my-4'
            pageClassName='border border rounded w-8 h-8 flex justify-center items-center hover:bg-gray-200'
            nextClassName='border border rounded w-32 h-8 flex justify-center items-center hover:bg-gray-200'
            previousClassName='border border rounded w-32 h-8 flex justify-center items-center hover:bg-gray-200'
            activeClassName='bg-gray-200'
            current_page={responseData.meta.currentPage ?? 1}
          />
        </div>
      )}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import StepData from './Components/StepData'
import PreviewProject from './Components/PreviewProject'
import { getSquads } from '../../providers/squadProvider'
import { useNavigate } from 'react-router'

export const NewProject = () => {
  const [step, setStep] = useState(1)
  const [squads, setSquads] = useState([])
  const [isLoadingSquads, setIsLoadingSquads] = useState(true)

  const navigate = useNavigate()

  const handleNextStep = data => {

    navigate(`/projetos/confirmar/${data.id}`)
  }

  const getSquadsOptions = async () => {
    const response = await getSquads()
    setSquads(response)
    setIsLoadingSquads(false)
  }

  useEffect(() => {
    getSquadsOptions()
  }, [])

  return (
    <div className="flex flex-col gap-5 pb-5">
      <div className="flex flex-col">
        <h3 className="text-2xl font-bold">{step === 1 ? 'Novo Projeto' : 'Projeto'}</h3>
        {step === 2 && <span className="text-neutral">200 horas</span>}
      </div>

      {step === 1 ? <StepData nextStep={handleNextStep} squads={squads} loadingSquads={isLoadingSquads} /> : <PreviewProject />}
    </div>
  )
}

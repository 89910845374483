import React from 'react'
import squadMock from '../../../../mocks/squads.json'

const PreviewProject = ({ data }) => {
  return (
    <div className="flex space-y-5 flex-col mt-5">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col md:w-5/12 lg:w-3/12 mr-20">
          <span className="text-lg">Disponibilidade de Squads</span>
          <span className="text-md text-gray-500">Resultado com base nos dados informados</span>
        </div>

        <div className="flex flex-col sm:mt-3 md:w-11/12 space-y-6">
          {squadMock.map(squad => (
            <div key={squad.id} className="p-5 flex flex-col text-gray-500 border rounded-lg bg-white">
              <span className="text-2xl font-bold text-slate-800">{squad.name}</span>
              <div className="flex">
                <span>{squad.hours}</span>
                <span className="ml-3">{squad.members}</span>
              </div>
              <hr className="w-full self-center my-3" />
              <span>{squad.start}</span>
              <span>{squad.delivery}</span>
              <span>{squad.timeForDay}</span>
              <span>{squad.cost}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end pt-3">
        <button className="btn btn-outline mr-3">Voltar</button>
        <button className="btn btn-primary text-white">Cadastrar novo projeto</button>
      </div>
    </div>
  )
}

export default PreviewProject

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { reportProject } from '../../providers/projectProvider';
import Projects from '../../components/Projects';
import ProjectBoard from '../../components/ProjectBoard';

export const Home = () => {
  const [unit, setUnit] = useState(null);
  const [time, setTime] = useState(15);

  useEffect(() => {
    document.title = 'Home | Squad Management Tool';
    handleFilter('15days');
  }, []);

  const { data, isLoading, isError } = useQuery('projectsReport', async() => {
    const projects = await reportProject();
    return projects;
  });

  const handleFilter = (time) => {
    switch (time) {
      case '12months':
        setTime(12);
        setUnit('month');
        break;
      case '6months':
        setTime(6);
        setUnit('week');
        break;
      case '30days':
        setTime(30);
        setUnit('day');
        break;
      case '7days':
        setTime(7);
        setUnit('day');
        break;
      default:
        setTime(15);
        setUnit('day');
        break;
    }
  }

  return (
    <>
      {/* Title and buttons to add Squad and Project */}
      <div className="container py-2">
        <div className="flex justify-between items-center py-4">
          <div>
            <h3 className="text-2xl font-bold">Projetos</h3>
            <p>
              <small>Acompanhe aqui</small>
            </p>
          </div>

          <div>
            <Link to="/squads/nova-squad" className="btn mr-2">
              <i className="pi pi-plus-circle"></i> Nova Squad
            </Link>
            <Link to="/projetos/novo-projeto" className="btn btn-primary text-white">
              <i className="pi pi-plus-circle"></i> Nova Projeto
            </Link>
          </div>
        </div>

        <div className="flex justify-between items-center py-2 mb-8">
          <div className="btn-group">
            <button onClick={() => handleFilter('12months')} className={`btn btn-sm ${time === 12 ? 'btn-dark' : 'btn-outline'}`}>12 meses</button>
            <button onClick={() => handleFilter('6months')} className={`btn btn-sm ${time === 6 ? 'btn-dark' : 'btn-outline'}`}>6 meses</button>
            <button onClick={() => handleFilter('30days')} className={`btn btn-sm ${time === 30 ? 'btn-dark' : 'btn-outline'}`}>30 dias</button>
            <button onClick={() => handleFilter('7days')} className={`btn btn-sm ${time === 7 ? 'btn-dark' : 'btn-outline'}`}>7 dias</button>
          </div>

          <div className="space-x-4">
            <button className="btn btn-outline btn-sm">
              <i className="pi pi-calendar"></i> Escolher data
            </button>

            <button className="btn btn-outline btn-sm">
              <i className="pi pi-filter"></i> Mais filtros
            </button>
          </div>
        </div>
      </div>

      {/* Filtro do board */}
      <div className="flex justify-between items-center mb-4">
        <div className="search w-56">
          <form>
            <input type="text" placeholder="Buscar" className="input input-bordered input-sm" />
            {/* <button type="submit" className="btn btn-outline-primary text-white">
              <i className="pi pi-search"></i>
            </button> */}
          </form>
        </div>
        
        <div className="">
          {/* Buttons 15 dias, Por projeto, Todos times, Mais filtros */}
          <div className="space-x-2">
            <button className="btn btn-outline btn-sm">15 dias <i className="pi pi-times text-orange-500"></i></button>
            <button className="btn btn-outline btn-sm">Por projeto <i className="pi pi-times text-orange-500"></i></button>
            <button className="btn btn-outline btn-sm">Todos times <i className="pi pi-times text-orange-500"></i></button>
            <button className="btn btn-outline btn-sm"><i className="pi pi-filter"></i> Mais filtros</button>
          </div>
        </div>
      </div>

      {isLoading && <div>Carregando...</div>}

      {isError && <div>Ocorreu um erro ao carregar os dados</div>}

      {!isLoading && !isError && data && (
        <>
          <p>Visão por: {unit}</p>

          {data.projects.length > 0 && (
            <ProjectBoard projects={data.projects} totalSquads={data.totalSquads} showBy={unit}>
              <Projects projects={data.projects} unit={unit} />
            </ProjectBoard>
          )}

          {data.projects.length === 0 && (
            <div className="text-center text-gray-500">
              <p>Nenhum projeto encontrado</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

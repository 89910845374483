import axios from "axios";

const http = axios.create({
    //  baseURL: 'http://localhost:4000',
    baseURL: 'https://apifiserv.rarolabs.com.br',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    }
});

export default http;
import { useState } from 'react'

export const ModalOptions = ({ title, options, onClose, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState('')

  const handleOptionChange = event => {
    setSelectedOption(event.target.value)
  }

  const handleConfirm = () => {
    onConfirm(selectedOption)
    setSelectedOption('')
    onClose()
  }

  return (
    <dialog id="modalOptions" className="modal">
      <div className="modal-box flex flex-col">
        <h3 className="my-5 font-bold text-lg">{title}</h3>
        <select value={selectedOption} onChange={handleOptionChange} className="p-2 border border-gray-200 rounded-md w-full mb-4">
          <option value="" disabled>
            Escolha uma opção
          </option>
          {options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn btn-outline">Close</button>
          </form>
          {selectedOption && (
            <button onClick={handleConfirm} className="btn btn-primary text-white">
              Confirmar
            </button>
          )}
        </div>
      </div>
    </dialog>
  )
}
